import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import CardRow from "../../components/CardRow"

export default function Event({ data, pageContext }) {
  const { t } = useTranslation()
  data = data === null ? {} : data //Ensure data is never null - Worst case, empty object
  const { routed } = pageContext.i18n //boolean	if false it means that the page is in default language
  const isDefaultLanguage = !routed
  const { language: currentLanguage } = pageContext.i18n
  var { events } = data.gcms || {} //If no gcms data, initialise as empty object
  const columnLimit = 2

  //Only show events with correct language
  if (!isDefaultLanguage && events) {
    var filteredEvents = []
    events.forEach(event => {
      if (event.localizations.length > 0) {
        event.localizations.forEach(localization => {
          if (localization.locale === currentLanguage) {
            //Replace fields with localised fields
            event.excerpt = localization.excerpt
            event.slug = localization.slug
            event.tags = localization.tags
            event.title = localization.title
            filteredEvents.push(event)
          }
        })
      }
    })
    events = filteredEvents
  }

  //Split into arrays of max size = no. of columns
  var eventRows = []
  while (events && events.length) {
    eventRows.push(events.splice(0, columnLimit))
  }

  //Render each column and add to array
  var columns = []
  eventRows.forEach((eventRow, index) => {
    columns.push(
      <div className="columns p-4" key={index}>
        <CardRow contentRow={eventRow} columnLimit={columnLimit} type="event" />
      </div>
    )
  })

  //If no blog posts, show message
  if (columns.length === 0) {
    columns.push(
      <section className="section has-text-centered" key="no-blog-message">
        <p>
          <Trans>
            Sorry, we don't have any events to show currently. Please check back
            here later!
          </Trans>
        </p>
      </section>
    )
  }
  return (
    <Layout subTitle={t("Events")}>
      <section className="section is-small has-text-centered">
        <h2 className="title is-2 is-spaced">
          <Trans>Events</Trans>
        </h2>
        <h3 className="subtitle is-4 is-spaced">
          <Trans>What's going on?</Trans>
        </h3>
      </section>
      <section className="section">{columns}</section>
    </Layout>
  )
}

//export page query
export const query = graphql`
  query EventsPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    gcms {
      events {
        id
        slug
        locale
        excerpt
        title
        address
        coverImage {
          url
        }
        date
        localizations {
          id
          slug
          locale
          excerpt
          title
        }
      }
    }
  }
`
